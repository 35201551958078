<template>
  <div>
    <v-container class="container--fluid text-center">
      <v-row>
        <!-- <v-col class="col-12 text-right">
          <v-btn class="mr-2" color="indigo" outlined dense @click="addAlarmModel = true">
            <v-icon left> mdi-plus </v-icon>
            Terim Ekle
          </v-btn>
        </v-col> -->

        <v-col class="col-12 text-right">
          <v-data-table
            :headers="headers"
            :items="desserts"
            :items-per-page="itemPerPage"
            class="elevation-1"
            dense
          >
            <template v-slot:item.description="{item}">
              <span v-html="$t('alarm.' + item.alarmCode)" />
            </template>

            <!--   <template v-slot:item.status="{item}">
              <span
                v-if="item.status == 'Active'"
                v-html="$t('global.Active')"
              />
              <span
                v-else
                v-html="$t('global.Passive')"
              />
            </template> -->
            <template v-slot:item.status="{item}">
              <v-row>
                <span>

                  <v-switch
                    v-model="item.statusValue"
                    flat
                    @change="formuleStatusUpdate(item)"
                  />

                </span>
              </v-row>
            </template>

            <!--  <template v-slot:item.action="{ item }">
              <v-icon small class="mr-2" @click="itemOpen(item)">
                mdi-pencil
              </v-icon>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <Snackbar
      :notify="notify"
    />
  </div>
</template>

<script>
import Snackbar from '@/views/components/app/Notify/Snackbar.vue';
import { getFarmFormuleList, getFarmFormuleUpdate } from '@/api/Request/formule';
import helper from '@/api/helper';

export default {
  components: {
    Snackbar,
  },
  data: () => ({

    desserts: [],
    addAlarmModel: false,
    selectedItem: [],
    settingAlarmModel: false,
    userList: [],
    itemPerPage: 10,
    notify: {
      snackbar: false,
      text: '',
      vertical: true,
      color: 'success',
    },
    // alarmList: [],
  }),

  computed: {
    headers() {
      return [
        { text: 'Adı', value: 'Name', width: '300px' },
        { text: 'Açıklama', value: 'Channel' },
        { text: 'Formul', value: 'Formule' },
        { text: '', value: 'unit' },
        // { text: "Durum", value: "status", width: "200px" },
        { text: 'Hesaplama', value: 'formuletype', width: '200px' },
        { text: 'Seçenek', value: 'status', width: '300px' },
      ];
    },
  },
  created() {
  },
  mounted() {
    this.selectedFarmFunc();
  },

  methods: {
    formuleStatus(status) {
      if (status === 'Active') {
        return true;
      }
      return false;
    },
    async selectedFarmFunc() {
      const { selectedFarm } = await this.$store.getters;
      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;
      this.getFarmFormule();
    },
    getFarmFormule() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
      };
      this.desserts = [];
      getFarmFormuleList(params).then((res) => {
        console.log(res);
        // TODO aktif pasif yapılacak
        if (helper.resIsEmpty(res)) {
          res.data.data.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.statusValue = this.formuleStatus(item.status) ?? false;
          });
          this.desserts = res.data.data;
          console.log(this.desserts);
        }
      });
    },
    formuleStatusUpdate(item) {
      // eslint-disable-next-line no-param-reassign
      item.status = item.statusValue === true ? 'Active' : 'Passive';
      const status1 = item.statusValue === true ? 'Aktif' : 'Pasif';

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          Key: item.Key,
        },
        data: {
          status: item.status,
          Key: item.Key,
        },
      };

      getFarmFormuleUpdate(params).then((res) => {
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = res.data.message;
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = `Formül ${status1} edildi.`;
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
      });
    },
  },
};
</script>

<style scoped></style>
