import request from '@/api/utils';

// eslint-disable-next-line import/prefer-default-export
export function getFarmFormuleList(formdata = {}, method = 'post') {
  const url = '/api/formule/farm/list';
  return request.Api(url, formdata, method);
}

// eslint-disable-next-line import/prefer-default-export
export function getFarmFormuleUpdate(formdata = {}, method = 'post') {
  const url = '/api/formule/farm/update';
  return request.Api(url, formdata, method);
}
